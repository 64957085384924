<template>
  <k-tooltip :tooltip="tooltip" :offset="offset" :placement="placement">
    <slot />
    <template #content>
      <button
        type="button"
        :data-name="elementName"
        :class="['k-btn', { 'btn-icon': iconOnly, 'btn-outline': outline }, buttonSize]"
        :tabindex="tabindex"
        :disabled="disabled"
        :style="containerStyle"
        @click.stop="onClick"
      >
        <resize-observer @notify="handleResize" />
        <span v-if="arrow" class="arrow" :class="arrowClass" />
        <div v-if="icon" class="btn-icon-wrap row between-xs middle-xs">
          <font-awesome-icon
            v-if="icon"
            :icon="icon"
            class="icon"
            :size="iconSize"
            :rotation="rotation"
          />
          <span v-if="label" class="custom-label">{{ label }}</span>
        </div>
        <span v-else class="custom-label">{{ label }}</span>
      </button>
    </template>
  </k-tooltip>
</template>

<script>
import $ from 'jquery';
import _ from 'underscore';

import commonHelper from 'adready-api/helpers/common';
import tooltipMixin from '../../adready-vue/components/mixins/tooltip-mixin';
import elementsMixin from './elements-mixin';

export default {
  name: 'KButton',

  mixins: [elementsMixin, tooltipMixin],

  props: {
    icon: {
      required: false,
      type: Array,
      default: null,
    },
    iconSize: {
      required: false,
      type: String,
      default: '1x',
    },
    iconRotation: {
      required: false,
      type: [String, Number],
      default: null,
    },
    size: {
      required: false,
      type: Number,
      default: 1,
    },
    outline: {
      required: false,
      type: Boolean,
      default: false,
    },
    // When set, show an arrow on the left or right of the button
    // Possible values: "left" or "right"
    arrow: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    containerStyle: {
      required: false,
      type: String,
      default: '',
    },
  },

  computed: {
    arrowClass() {
      if (this.arrow) {
        return `arrow-${this.arrow}`;
      }
      return undefined;
    },

    elementName() {
      return commonHelper.elementName(this.label);
    },

    buttonSize() {
      return `btn-size-${this.size}`;
    },

    iconOnly() {
      return this.icon && !this.label;
    },

    rotation() {
      if (!this.iconRotation) {
        return null;
      }
      if (_.isString(this.iconRotation)) {
        return parseInt(this.iconRotation, 10);
      }
      return this.iconRotation;
    },
  },

  mounted() {
    if (this.arrow) {
      this.positionArrow();
    }
  },

  methods: {
    positionArrow() {
      if (!this.$el) {
        return;
      }
      const el = $(this.$el);
      const rect = el.position(); // use parent offset position
      const top = rect.top + el.height() / 2 - 6;

      let left;
      if (this.arrow === 'left') {
        left = rect.left - 6;
      } else if (this.arrow === 'right') {
        left = rect.left + el.width();
      }

      el.find('.arrow').css({ top, left });
    },

    // events
    handleResize() {
      if (this.arrow) {
        this.positionArrow();
      }
    },

    onClick(ev) {
      // We need to emit here, otherwise this method won't be available for
      // listening to by consumers of this component.
      //
      // From the docs for v-on:
      // https://vuejs.org/v2/api/#v-on
      // When used on a normal element, it listens to native DOM events only.
      // When used on a custom element component, it listens to custom events
      // emitted on that child component.
      this.$emit('click', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.k-btn {
  display: inline-block;
  min-width: 100px;
  font-size: 12px;
  font-weight: 600;
  color: #303335;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  user-select: none;
  // background-color: $yellow;
  // border: 1px solid $grey_7;
  border-radius: 0;
  outline: none;
  transition: all 0.6s ease;
  appearance: none;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  span.custom-label {
    display: block;
    padding: 15px 26px;
  }

  &.btn-icon {
    min-width: 24px;
    padding: 5px;
  }

  &.btn-size-1 {
    min-width: 20px;
    font-size: 10px;
    font-weight: 600;
    span.custom-label {
      padding: 4px 10px;
    }
  }
  &.btn-size-2 {
    min-width: 20px;
    font-size: 11px;
    font-weight: 600;
    span.custom-label {
      padding: 6px 10px;
    }
  }
  &.btn-size-3 {
    font-size: 12px;
    span.custom-label {
      padding: 10px 18px;
    }
  }
  &.btn-size-4 {
    font-size: 13px;
    span.custom-label {
      padding: 12px 22px;
    }
  }
  &.btn-size-5 {
    font-size: 14px;
    span.custom-label {
      padding: 15px 26px;
    }
  }
  &.btn-size-6 {
    font-size: 16px;
    span.custom-label {
      padding: 18px 32px;
    }
  }

  .btn-icon-wrap {
    padding: 15px 26px;

    svg {
      margin: 0 10px 0 0;
    }

    span.custom-label {
      padding: 0;
    }
  }

  &.btn-adjust {
    color: #fff;
    background-color: $dr_blue;
    .btn-icon-wrap {
      padding: 0;
      svg {
        margin: 0;
      }
    }
  }

  // left/right arrow [optional]
  .arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    transition: border 0.6s ease;

    // following styles defined in themes.scss
    // &.arrow-left {
    //   border-right: 6px solid $yellow;
    // }
    // &.arrow-right {
    //   border-left: 6px solid $yellow;
    // }
  }
}

.k-btn {
  background-color: #00b3aa;
  &.btn-outline {
    color: $grey_3;
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 1px $grey_7;
  }

  &:hover,
  &.btn-active {
    color: $white;
    background-color: $grey_3;
  }
}
</style>
